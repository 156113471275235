@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.5/dist/web/static/pretendard-dynamic-subset.css');
@tailwind base;
/* @tailwind components; */
@tailwind utilities;

@layer base {
  :root {
    --white: #fff;
    --primary-99: #002133;
    --primary-90: #004166;
    --primary-50: #33b6ff;
    --primary-40: #66c8ff;
    --primary-30: #99daff;
    --primary-20: #ccedff;
    --primary-10: #f5fbff;
    --gray-10: #f7f8f9;
    --primary-80: #006299;
    --primary-70: #0083cc;
    --primary-60: #00a3ff;
    --gray-30: #c9cdd2;
    --gray-20: #e8ebed;
    --gray-40: #abb3bb;
    --gray-50: #9da7b0;
    --gray-60: #72787f;
    --gray-70: #454c53;
    --gray-90: #131415;
    --gray-80: #26282b;
    --background-light: #fafafb;
    --background-dark: #f2f3f5;
    --background-darkest: #e7e9ee;
    --dimcolor-50: rgba(19, 20, 21, 0.5);
    --dimcolor-20: rgba(19, 20, 21, 0.2);
    --point-default: #ff521c;
    --border-1: #eceff2;
    --border-2: #dce1e5;
    --border-3: #c9cdd2;
    --border-4: #bdc3ca;
    --danger-light: #ffeaea;
    --danger-default: #f84343;
    --success-light: #c6dbef;
    --danger-dark: #cf0d0d;
    --success-default: #1f9cd1;
    --success-dark: #2f56a2;
    --point-light: #fff2b0;
  }

  body {
    margin: 0;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: -0.36px;
  }

  .Display1Bold {
    font-family: Pretendard;
    font-size: 56px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .Display1Semibold {
    font-family: Pretendard;
    font-size: 56px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .Display1Light {
    font-family: Pretendard;
    font-size: 56px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .Display2Semibold-2 {
    font-family: Pretendard;
    font-size: 40px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .Display2Light {
    font-family: Pretendard;
    font-size: 40px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .Display2Bold {
    font-family: Pretendard;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: -0.64px;
  }

  .Display2Semibold {
    font-family: Pretendard;
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .Display3Light {
    font-family: Pretendard;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .Display3SemiBold {
    font-family: Pretendard;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .Header1 {
    font-family: Pretendard;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.48px;
  }

  .Header2-2 {
    font-family: Pretendard;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: -0.44px;
  }

  .Header2 {
    font-family: Pretendard;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: -0.44px;
  }

  .Header3 {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.4px;
  }

  .Title1Bold {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: -0.36px;
  }

  .Title1Medium {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: -0.36px;
  }

  .Title2 {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.32px;
  }

  .Body1 {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: -0.32px;
  }

  .Title3Bold {
    font-family: Pretendard;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.3px;
  }

  .Title3Medium {
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.3px;
  }

  .Title4Bold {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.28px;
  }

  .Title4Medium {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.28px;
  }

  .Body2 {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.28px;
  }

  .Body3 {
    font-family: Pretendard;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: -0.26px;
  }

  .Title5Bold {
    font-family: Pretendard;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.24px;
  }

  .Title5Medium {
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.24px;
  }

  .Body4 {
    font-family: Pretendard;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.24px;
  }

  ul {
    margin: 0;
    padding: 0;
  }
}
